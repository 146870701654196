import dayjs from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import { req, withParams } from 'vuelidate/lib/validators/common'

dayjs.extend(isSameOrAfter)

export default (attr, sourceformat, targetformat) =>
  withParams({ type: 'dateSameOrAfter', attr, sourceformat, targetformat }, (value) => {
    return (
      !req(value) ||
      dayjs(value, sourceformat, true).isSameOrAfter(
        dayjs(typeof attr === 'function' ? attr() : attr, targetformat || sourceformat, true)
      )
    )
  })
